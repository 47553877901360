import * as yup from 'yup';

export const formSchema = yup.object().shape({
  user_email: yup.string().email('Please enter a valid email address').required('Email is required'),
  from_name: yup.string().min(1, 'Name must be at least 1 character').max(32, 'Name must be at most 32 characters').required('Name is required'),
  message: yup
    .string()
    .min(1, 'Message must be at least 1 character')
    .max(400, 'Message must be at most 400 characters')
    .required('Message is required'),
});
