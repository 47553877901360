import React, { useState, useEffect } from 'react';
import './App.css';
import Complete from './Complete';
import HashLoader from 'react-spinners/HashLoader';
import 'animate.css';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className='App animate__animated animate__fadeIn'>
      {loading ? (
        <HashLoader color='#38b2ac' loading={loading} size={80} className='loader' />
      ) : (
        <div className='animate__animated animate__fadeIn'>
          <Complete />
        </div>
      )}
    </div>
  );
};

export default App;
