import React from 'react';
import './footer.css';
import logo from '../../assets/artealblue-bg.webp';
import githubLogo from '../../assets/github.png';
import linkedInLogo from '../../assets/linkedin.png';
import googleDev from '../../assets/google.png';
import fiverrLogo from '../../assets/fiverr.png';
import upworkLogo from '../../assets/upwork.png';
import leetcodeLogo from '../../assets/leetcode.png';
import hackerrankLogo from '../../assets/hackerrank.png';
import codewarsLogo from '../../assets/codewars.png';

const Footer = () => {
  return (
    <div className='ar__footer-container'>
      <div className='ar__footer-content'>
        <div className='ar__footer-content-logo'>
          <a href='https://ateeqrana.live'>
            <img src={logo} alt='Ateeq Rana Logo' />
          </a>
          <p>Living, learning, & leveling up one day at a time.</p>
        </div>
        <div className='ar__footer-content-links-container'>
          <div className='ar__footer-content-links-set'>
            <p className='link-type'>Social</p>
            <ul>
              <li>
                <img src={linkedInLogo} alt='Linkedin' />
                <a href='https://www.linkedin.com/in/ateeq-rana-1b03b8201/' target='_blank' rel='noreferrer'>
                  LinkedIn
                </a>
              </li>

              <li>
                <img src={googleDev} alt='Google Developer' />
                <a href='https://developers.google.com/profile/u/ateeqrana7' target='_blank' rel='noreferrer'>
                  Google Developer
                </a>
              </li>
            </ul>
          </div>
          <div className='ar__footer-content-links-set'>
            <p className='link-type'>Work</p>
            <ul>
              <li>
                <img src={githubLogo} alt='GitHub' />
                <a href='https://github.com/AteeqRana7' target='_blank' rel='noreferrer'>
                  GitHub
                </a>
              </li>
              <li>
                <img src={upworkLogo} alt='Upwork' />
                <a href='https://www.upwork.com/freelancers/~01a8c1e13a1c6719fc' target='_blank' rel='noreferrer'>
                  Upwork
                </a>
              </li>
              <li>
                <img src={fiverrLogo} alt='Fiverr' />
                <a href='https://www.fiverr.com/ateeq_rana7' target='_blank' rel='noreferrer'>
                  Fiverr
                </a>
              </li>
            </ul>
          </div>
          <div className='ar__footer-content-links-set'>
            <p className='link-type'>Competitive Platforms</p>
            <ul>
              <li>
                <img src={hackerrankLogo} alt='HackerRank' />
                <a href='https://www.hackerrank.com/ateeq_rana7' target='_blank' rel='noreferrer'>
                  HackerRank
                </a>
              </li>
              <li>
                <img src={leetcodeLogo} alt='LeetCode' />
                <a href='https://leetcode.com/AteeqRana7/' target='_blank' rel='noreferrer'>
                  LeetCode
                </a>
              </li>
              <li>
                <img src={codewarsLogo} alt='Codewars' />
                <a href='https://www.codewars.com/users/AteeqRana7' target='_blank' rel='noreferrer'>
                  Codewars
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='ar__footer-disclaimer'>
        Copyright © 2024 Ateeq Rana. All Rights Reserved.
        <br></br>
        Built & Designed with 💚 by Ateeq Rana.
      </div>
    </div>
  );
};

export default Footer;
