import React from 'react';
import './App.css';
import Navbar from './components/navbar/Navbar';
import Header from './containers/header/Header';
import Footer from './containers/footer/Footer';
import Services from './containers/services/Services';
import About from './containers/whatAr/WhatAr';
import Portfolio from './containers/portfolio/Portfolio';
import Contact from './containers/contact/Contact';
import ScrollToTop from 'react-scroll-to-top';

const Complete = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <About />
      <Portfolio />
      <Services />
      <Contact />
      <Footer />
      <ScrollToTop smooth color='#38b2ac' height='25' />
    </div>
  );
};

export default Complete;
