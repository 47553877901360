import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';

import Feature from '../../components/feature/Feature';

import 'react-vertical-timeline-component/style.min.css';
import './whatAr.css';

const WhatAr = () => {
  return (
    <>
      <div className='ar section__margin' id='about'>
        <div className='ar-feature'>
          <Feature
            title='About Me.'
            text="I'm a web developer from Pakistan with a Bachelor's degree in Computer Science, driven by a passion for building impactful digital solutions. As an Associate Web Engineer at Bitsol Technologies, I focus on full-stack development with JavaScript and TypeScript. I enjoy creating intuitive and engaging web applications and am always on the lookout for new ways to innovate in the world of web development. Let’s collaborate to craft outstanding web experiences together!"
          />
        </div>
        <div className='ar-heading'>
          <h1 className='gradient__text'>An Experienced Full Stack Developer</h1>
        </div>
      </div>
      <header className='portfolio__header'>
        <h1 className='gradient__text-secondary'>Experience.</h1>
      </header>
      <div style={{ margin: '2rem 1rem 6rem 1rem' }}>
        <VerticalTimeline lineColor='#12161f'>
          <VerticalTimelineElement
            className='vertical-timeline-element--work'
            contentStyle={{ background: 'var(--color-secondary)', color: 'var(--color-primary)' }}
            contentArrowStyle={{ borderRight: '7px solid  var(--color-secondary)' }}
            date='July 2023 - Present'
            iconStyle={{ background: 'var(--color-secondary)', color: 'var(--color-primary)' }}
            icon={<WorkIcon />}
          >
            <h3 className='vertical-timeline-element-title' style={{ color: 'var(--color-secondary-background)' }}>
              Associate Web Engineer @ BitSol Technologies
            </h3>
            <h4 className='vertical-timeline-element-subtitle' style={{ color: 'var(--color-secondary-background)' }}>
              Islamabad, Pakistan
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className='vertical-timeline-element--work'
            contentStyle={{ background: 'var(--color-secondary)', color: 'var(--color-primary)' }}
            contentArrowStyle={{ borderRight: '7px solid  var(--color-secondary)' }}
            date='March 2023 - June 2023'
            iconStyle={{ background: 'var(--color-secondary)', color: 'var(--color-primary)' }}
            icon={<WorkIcon />}
          >
            <h3 className='vertical-timeline-element-title' style={{ color: 'var(--color-secondary-background)' }}>
              Full Stack Development Intern @ Bytewise
            </h3>
            <h4 className='vertical-timeline-element-subtitle' style={{ color: 'var(--color-secondary-background)' }}>
              Islamabad, Pakistan
            </h4>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>

      <header className='portfolio__header'>
        <h1 className='gradient__text'>Education.</h1>
      </header>
      <div style={{ margin: '2rem 1rem 6rem 1rem' }}>
        <VerticalTimeline lineColor='#12161f'>
          <VerticalTimelineElement
            className='vertical-timeline-element--work'
            contentStyle={{ background: 'var(--color-accent)', color: 'var(--color-primary)' }}
            contentArrowStyle={{ borderRight: '7px solid  var(--color-accent)' }}
            date='2019 - 2023'
            iconStyle={{ background: 'var(--color-accent)', color: 'var(--color-primary)' }}
            icon={<SchoolIcon />}
          >
            <h3 className='vertical-timeline-element-title' style={{ color: 'var(--color-secondary-background)' }}>
              Bachelors of Science in Computer Science
            </h3>
            <h4 className='vertical-timeline-element-subtitle' style={{ color: 'var(--color-secondary-background)' }}>
              SZABIST University, Islamabad, Pakistan.
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className='vertical-timeline-element--work'
            contentStyle={{ background: 'var(--color-accent)', color: 'var(--color-primary)' }}
            contentArrowStyle={{ borderRight: '7px solid  var(--color-accent)' }}
            date='2015 - 2017'
            iconStyle={{ background: 'var(--color-accent)', color: 'var(--color-primary)' }}
            icon={<SchoolIcon />}
          >
            <h3 className='vertical-timeline-element-title' style={{ color: 'var(--color-secondary-background)' }}>
              Cambridge International AS & A Levels
            </h3>
            <h4 className='vertical-timeline-element-subtitle' style={{ color: 'var(--color-secondary-background)' }}>
              SISA, Lahore, Pakistan.
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className='vertical-timeline-element--work'
            contentStyle={{ background: 'var(--color-accent)', color: 'var(--color-primary)' }}
            contentArrowStyle={{ borderRight: '7px solid  var(--color-accent)' }}
            date='2012 - 2014'
            iconStyle={{ background: 'var(--color-accent)', color: 'var(--color-primary)' }}
            icon={<SchoolIcon />}
          >
            <h3 className='vertical-timeline-element-title' style={{ color: 'var(--color-secondary-background)' }}>
              Cambridge International IGCSEs
            </h3>
            <h4 className='vertical-timeline-element-subtitle' style={{ color: 'var(--color-secondary-background)' }}>
              British School in Baku, Azerbaijan.
            </h4>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
      <header className='portfolio__header' id='tools'>
        <h1 className='gradient__text-secondary'>Tools & Technologies.</h1>
      </header>
      <div className='portfolio__container'>
        <i className='devicon-javascript-plain colored skill-icon'></i>
        <i className='devicon-typescript-plain colored skill-icon'></i>
        <i className='devicon-react-original colored skill-icon'></i>
        <i className='devicon-html5-plain-wordmark colored skill-icon'></i>
        <i className='devicon-css3-plain-wordmark colored skill-icon'></i>
        <i className='devicon-sass-original colored skill-icon'></i>
        <i className='devicon-materialui-plain colored skill-icon'></i>
        <i className='devicon-tailwindcss-original colored skill-icon'></i>
        <i className='devicon-bootstrap-plain colored skill-icon'></i>
        <i className='devicon-jest-plain colored skill-icon'></i>
      </div>
      <div className='portfolio__container'>
        <i className='devicon-nodejs-plain-wordmark colored skill-icon'></i>
        <i className='devicon-express-original colored skill-icon'></i>
        <i className='devicon-graphql-plain-wordmark colored skill-icon'></i>
        <i className='devicon-mongodb-plain-wordmark colored skill-icon'></i>
        <i className='devicon-firebase-plain-wordmark colored skill-icon'></i>
        <i className='devicon-googlecloud-plain skill-icon'></i>
        <i className='devicon-postgresql-plain colored skill-icon'></i>
        <i className='devicon-mocha-plain colored skill-icon'></i>
      </div>
      <div className='portfolio__container'>
        <i className='devicon-git-plain colored skill-icon'></i>
        <i className='devicon-github-original colored skill-icon'></i>
        <i className='devicon-circleci-plain-wordmark colored skill-icon'></i>
        <i className='devicon-slack-plain skill-icon'></i>
        <i className='devicon-jira-plain colored skill-icon'></i>
        <i className='devicon-trello-plain colored skill-icon'></i>
        <i className='devicon-vscode-plain colored skill-icon'></i>
      </div>
    </>
  );
};

export default WhatAr;
