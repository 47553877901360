import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import Skeleton from '@mui/material/Skeleton';
import WavingHandRoundedIcon from '@mui/icons-material/WavingHandRounded';

import heroPic from '../../assets/hero3-cropped.svg';
import './header.css';

const Header = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className='ar__header section__padding'>
      <div className='ar__header-content'>
        <p className='highlight'>
          Hi there
          <WavingHandRoundedIcon fontSize='small' sx={{ margin: '0 0.25rem' }} />! I am
        </p>
        <h1 className='gradient__text'>Ateeq Rana,</h1>
        <h1 className='gradient__text-secondary extra-width'>
          <TypeAnimation
            sequence={['I build things for the web.', 2000, 'I am a Web Developer.', 2000, 'I am a Full Stack Developer.', 3000]}
            wrapper='span'
            cursor={true}
            repeat={Infinity}
            preRenderFirstString
          />
        </h1>
        <p className='descrip'>Crafting Digital Experiences, One Line of Code at a Time.</p>

        <div className='ar__header-content__input'>
          <a href='#services'>
            <button type='button' className='getStartedButton'>
              View Services
            </button>
          </a>
        </div>
      </div>
      <div className='ar__header-image'>
        {!isImageLoaded && <Skeleton className='hero-skeleton' variant='rounded' animation='wave' />}
        <img src={heroPic} alt='coding web developer' onLoad={handleImageLoad} style={{ display: isImageLoaded ? 'block' : 'none' }} />
      </div>
    </div>
  );
};

export default Header;
