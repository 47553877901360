import React from 'react';
import Feature from '../../components/feature/Feature';
import './services.css';

const featuresData = [
  {
    title: 'Frontend Development (HTML & CSS/SCSS with JavaScript)',
    text: "Want a simple landing page for your product/service? Don't worry, I've got you covered!",
  },
  {
    title: 'Frontend Development (React Js)',
    text: 'A single page web app.',
  },
  {
    title: 'Backend Development (Node.js)',
    text: 'A robust backend to link with your existing frontend.',
  },
  {
    title: 'Full Stack Development',
    text: 'A dynamic web application according to your needs & requirements.',
  },
];

const Services = () => {
  return (
    <div className='ar__features section__padding' id='services'>
      <div className='ar__features-heading'>
        <h1 className='gradient__text'>Services.</h1>
        <p>Make every project a success. Map out each requirement and get yourself a fully responsive website/web application.</p>
      </div>
      <div className='ar__features-container'>
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  );
};

export default Services;
