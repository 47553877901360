import React from 'react';
import ContactForm from '../../components/contactForm/ContactForm';
import illustrationDark from '../../assets/email2-cropped.svg';
import './contact.css';

const Contact = () => {
  return (
    <div id='contact'>
      <h1 className='section__heading gradient__text'>Contact.</h1>
      <div className='contact__content'>
        <div className='contact__left'>
          <img src={illustrationDark} alt='' className='contact-illustration' />
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
