import React from 'react';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { formSchema } from './formValidation';
import { SERVICE_ID, TEMPLATE_ID, USER_ID } from './../../services/emailjs';
import './contactForm.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  // const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [resMsg, setResMsg] = React.useState('');
  const [resType, setResType] = React.useState('');
  console.debug(isSubmitting);
  const handleOnSubmit = async (data) => {
    try {
      await emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID);
      setResMsg('Message Sent Successfully');
      setResType('success');
      setOpen(true);
      reset();
    } catch (error) {
      setResMsg('Oops, something went wrong');
      setResType('error');
      setOpen(true);
      console.error(error);
    }
  };
  const handleReset = () => {
    reset();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='formApp'>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <h2>Interested in collaborating with me?</h2>
        <input
          {...register('user_email')}
          type='email'
          placeholder='Email*'
          disabled={isSubmitting}
          className={errors.user_email?.message && 'error-outline'}
        />
        <p className='error-msg'>{errors.user_email?.message}</p>
        <input
          {...register('from_name')}
          type='text'
          placeholder='Name*'
          disabled={isSubmitting}
          className={errors.from_name?.message && 'error-outline'}
        />
        <p className='error-msg'>{errors.from_name?.message}</p>
        <textarea
          {...register('message')}
          cols='30'
          rows='10'
          placeholder='Message*'
          disabled={isSubmitting}
          className={errors.message?.message && 'error-outline'}
        ></textarea>
        <p className='error-msg'>{errors.message?.message}</p>
        <button type='submit' disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} sx={{ color: 'var(--color-accent)' }} /> : 'Send'}
        </button>
        <button type='reset' onClick={handleReset} disabled={isSubmitting}>
          Reset
        </button>
      </form>
      <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} autoHideDuration={5000}>
        <Alert onClose={handleClose} severity={resType} sx={{ width: '100%', fontFamily: `'Jost', sans-serif`, fontSize: '1rem' }}>
          {resMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactForm;
