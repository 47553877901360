import React, { useEffect, useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Skeleton } from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import './navbar.css';

import logo from '../../assets/artealblue-bg.webp';

const Menu = () => (
  <>
    <p>
      <a href='#home'>
        <HomeRoundedIcon className='nav__link__icon' />
        Home
      </a>
    </p>
    <p>
      <a href='#about'>
        <InfoRoundedIcon className='nav__link__icon' />
        About
      </a>
    </p>
    <p>
      <a href='#tools'>
        <ConstructionRoundedIcon className='nav__link__icon' />
        Tools
      </a>
    </p>
    <p>
      <a href='#services'>
        <DesignServicesRoundedIcon className='nav__link__icon' />
        Services
      </a>
    </p>

    <p>
      <a href='#contact'>
        <ContactMailRoundedIcon className='nav__link__icon' />
        Contact
      </a>
    </p>
  </>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [theme, setTheme] = useState('dark-theme');
  const ToggleTheme = () => {
    if (theme === 'dark-theme') {
      setTheme('light-theme');
    } else {
      setTheme('dark-theme');
    }
  };
  const handleLogoLoad = () => {
    setLogoLoaded(true);
  };
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <div className='ar__navbar' id='home'>
      <div className='ar__navbar-links'>
        <div className='ar__navbar-links_logo'>
          <a href='https://ateeqrana.live'>
            {!logoLoaded && <Skeleton variant='circular' className='custom-logo-skeleton' />}
            <img src={logo} alt='logo' onLoad={handleLogoLoad} style={{ display: logoLoaded ? 'block' : 'none' }} />
          </a>
        </div>
        <div className='ar__navbar-links_container'>
          <Menu />

          <div className='toggle__container toggle__container__two'>
            <input type='checkbox' className='checkbox' id='checkbox' onClick={ToggleTheme} />
            <label htmlFor='checkbox' className='label'>
              <LightModeRoundedIcon className='toggle__icon' />
              <DarkModeRoundedIcon className='toggle__icon' />
              <div className='ball'></div>
            </label>
          </div>
        </div>
      </div>
      <div className='ar__navbar-menu'>
        {toggleMenu ? (
          <RiCloseLine color='#38b2ac' size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line color='#38b2ac' size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className='ar__navbar-menu_container scale-up-center'>
            <div className='ar__navbar-menu_container-links'>
              <Menu />
            </div>
            <div className='toggle__container toggle__container__two'>
              <input type='checkbox' className='checkbox' id='checkbox' onClick={ToggleTheme} />
              <label htmlFor='checkbox' className='label'>
                <LightModeRoundedIcon className='toggle__icon' />
                <DarkModeRoundedIcon className='toggle__icon' />
                <div className='ball'></div>
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
