import React from 'react';
import './feature.css';

const Feature = ({ title, text, jobs = [] }) => {
  return (
    <div className='gpt3__features-container__feature'>
      <div className='gpt3__features-container__feature-title'>
        <h1 className='gradient__text'>{title}</h1>
      </div>
      <div className='gpt3__features-container_feature-text'>
        <p>{text}</p>
      </div>
      {jobs?.length ? (
        <div>
          {jobs.map((job) => (
            <div key={`job-${job}`} className='gpt3__features-container_feature-text' style={{ margin: '1rem 0' }}>
              <p>{job}</p>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Feature;
