import React from 'react';

import 'animate.css';
import './portfolio.css';

// const ActualPortfolio = () => {
//   let containers = ['portfolio_htmlcss', 'portfolio_react'];
//   let btncontainers = ['htmlcss', 'react'];

//   const changeActive = (id, id2) => {
//     for (let i = 0; i < containers.length; i++) {
//       document.querySelector(`.${id}`).classList.remove('hidden');
//       document.querySelector(`.${id2}`).classList.add('active');
//       if (containers[i] !== id) {
//         document.querySelector(`.${containers[i]}`).classList.add('hidden');
//       }
//       if (btncontainers[i] !== id2) {
//         document.querySelector(`.${btncontainers[i]}`).classList.remove('active');
//       }
//     }
//   };
//   return (
//     <div className='portfolio' id='portfolio'>
//       <header className='portfolio__header'>
//         <h1 className='gradient__text'>Portfolio.</h1>
//         <button onClick={() => changeActive('portfolio_htmlcss', 'htmlcss')} className='btn-portfolio htmlcss ' id='htmlcss'>
//           Websites
//         </button>
//         <button onClick={() => changeActive('portfolio_react', 'react')} className='btn-portfolio react active' id='react'>
//           Web Apps
//         </button>
//       </header>
//       <div className='portfolio__container portfolio_htmlcss hidden animate__animated animate__slideInUp'>
//         <Project image={gdscReactMockup} title='GDSC SZABIST-Islamabad' skills={['React Js']} />
//         <Project image={shortlyMockup} title='Shortly - Shorten URLs' skills={['React Js', 'External API']} />
//         <Project image={fyloMockup} title='Fylo - Landing Page' skills={['HTML5', 'CSS3']} />
//         <Project image={clipboardMockup} title='Clipboard - Landing Page' skills={['HTML5', 'CSS3']} />
//         <Project image={tcaMockup} title='The Community Alliance' skills={['React Js']} />
//         <Project image={huddleMockup} title='Huddle - Landing Page' skills={['HTML5', 'CSS3']} />
//         <Project image={architectMockup} title='Interior Designer - Portfolio Website' skills={['React Js']} />
//         <Project image={shopMdMockup} title='ShopMD' skills={['HTML5', 'CSS3', 'JavaScript']} />
//         <Project image={gdscOneLightMockup} title='GDSC SZABIST Islamabad' skills={['HTML5', 'CSS3', 'JavaScript']} />
//         <Project image={cloudMockup} title='Cloud Hosting - Moshify' skills={['HTML5', 'CSS3', 'JavaScript']} />
//       </div>
//       <div className='portfolio__container portfolio_react  animate__animated animate__slideInUp'>
//         <Project image={openTaskMockup} title='OpenTask - Process Organiser' skills={['React Js', 'Node Js', 'Express Js', 'MongoDB', 'Socket.io']} />
//         <Project image={bmsMockup} title='Bookstore' skills={['React Js', 'Node Js', 'Express Js', 'PostgreSQL', 'Redux']} />
//         <Project image={bmsDarkMockup} title='Bookstore' skills={['React Js', 'Nest Js', 'Express Js', 'PostgreSQL', 'Redux']} />
//         <Project image={todoApp} title='To-do Application' skills={['React Js', 'Node Js', 'Express Js', 'MongoDB', 'RTK Query']} />
//       </div>
//     </div>
//   );
// };

const Portfolio = () => {
  return <></>;
};

export default Portfolio;
